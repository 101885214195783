import * as React from "react";
import { Link } from "gatsby";

import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function Deception() {
  return (
    <Layout>
      <Helmet title="Deception | Michael Weslander" />

      <h1>Deception</h1>
      <h2>Michael Weslander</h2>
      <h4>Key: Am</h4>
      <h4>Tempo: 110</h4>
      <h4>Comfort: 2</h4>
      <Link to="/">List</Link>

      <Columns columns="3">
        <Verse>
          <p>As I stand on the edge of this garden</p>
          <p>I can't see the fruit for the leaves</p>
          <p>All I can see is an ocean</p>
          <p>Leaves wave around in the breeze</p>
        </Verse>
        <Verse>
          <p>As I sit in my car at this stoplight</p>
          <p>I can't feel the ground for the wheels</p>
          <p>All I can feel is an ocean</p>
          <p>Traffic rolls on towards the sea</p>
        </Verse>
        <Chorus>
          <p>And if I happen to find what I've been looking for</p>
          <p>In this crowded street</p>
          <p>I've been decieved</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
